import title from '../../assets/icons/titulo.png';

const Title = () => {
    return (
        <section className="title">
            <img src={title} alt="Alba Troiteiro"></img>
        </section>
    );
};
export default Title;
