import { useEffect, useState } from 'react';
import botonmenusin from '../../assets/icons/botonmenusinmano.png';
import mano from '../../assets/icons/manomenu.png';
import botonpulsado from '../../assets/icons/botonmenupulsado.png';
import './style.css';
import { Link } from 'react-router-dom';
import RotateText from '../RotateText';
import asterisco from '../../assets/icons/asterisco.png';

const NavBarMobile = () => {
    const [mostrarNavBar, setMostrarNavBar] = useState(true);
    const [imagenBoton, setImagenBoton] = useState(botonmenusin);
    const [isRotated, setIsRotated] = useState(false);

    const handleImageChange = () => {
        if (imagenBoton === botonmenusin) {
            setImagenBoton(botonpulsado);
        } else {
            setImagenBoton(botonmenusin);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 800) {
                setMostrarNavBar(true);
            } else {
                setMostrarNavBar(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {mostrarNavBar && (
                <div className="botonmenu">
                    <img src={mano} alt="Mano"></img>
                    <button onClick={handleImageChange}>
                        <img src={imagenBoton} alt="Botón Menú"></img>
                    </button>
                </div>
            )}
            <article
                className={`navbarmobile ${
                    imagenBoton === botonpulsado ? 'show' : ''
                }`}
            >
                <div>
                    <img src={asterisco} alt="asterisco"></img>
                    <Link to="/">TRABAJOS</Link>
                    <img src={asterisco} alt="asterisco"></img>
                </div>
                <div>
                    <img src={asterisco} alt="asterisco"></img>
                    <Link to="/Portraits">RETRATOS</Link>
                    <img src={asterisco} alt="asterisco"></img>
                </div>
                <div>
                    <img src={asterisco} alt="asterisco"></img>
                    <Link to="/Drawings">DIBUJOS</Link>
                    <img src={asterisco} alt="asterisco"></img>
                </div>
                <div>
                    <img src={asterisco} alt="asterisco"></img>
                    <Link to="/AboutMe">
                        <RotateText
                            isRotated={isRotated}
                            setIsRotated={setIsRotated}
                        />
                    </Link>
                    <img src={asterisco} alt="asterisco"></img>
                </div>
            </article>
        </>
    );
};
export default NavBarMobile;
