import { useEffect, useState } from 'react';
import NavBar from '../NavBar';
import './style.css';

const Header = () => {
    const [mostrarNavBar, setMostrarNavBar] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 800) {
                setMostrarNavBar(true);
            } else {
                setMostrarNavBar(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <header>{mostrarNavBar && <NavBar></NavBar>}</header>;
};
export default Header;
